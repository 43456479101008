import { nanoid } from 'nanoid';

// HEAD DATA
export const headData = {
  title: 'William Farley Portfolio', // e.g: 'Name | Developer'
  lang: 'en', // e.g: en, es, fr, jp
  description: 'Welcome to my portfolio site', // e.g: Welcome to my website
};

// HERO DATA
export const heroData = {
  title: 'William Farley',
  subtitle: 'Aspiring Engineer and Artist',
  cta: '',
};

// ABOUT DATA
export const aboutData = {
  img: 'profile.jpg',
  paragraphOne: "I'm a native of Boston, MA and an alum of Boston College and Noble and Greenough. Today, I'm an SDE for Amazon and am learning and growing as a developer every day. I've been able to take on new challenges like running standup, managing subteams, and leading design reviews. I love the impact I get to have in my role.",
  paragraphTwo: "I discovered my love for coding during Freshman year of college while taking John Gallaugher's course on Swift. I declared a CS major and here I am years later. I believe in wearing many hats, and I still have the old Classics/English major in me. I believe in stealing like an artist and that the best things to steal may be somewhere in the past.",
  paragraphThree: "In my free time, I love to cook and have been working through Julia Child's Mastering the Art of French Cooking. I play Men's League Baseball and love finding new opportunities to compete. Recently I fell in love with Chess. I love to travel and hope to someday have the flexibility to see the world and work remotely for chunks of time from different places. As the late great Anthony Bourdain would say, I'm just here discovering the ride.",
};

// PROJECTS DATA
export const projectsData = [
  {
    id: nanoid(),
    img: 'aws.jpg',
    title: 'Amazon FSx (AWS) - Software Development Engineer',
    info: [
      'Followed rigorous design and planning process',
      'Implemented Service Quotas integration',
      'Overhauled front end testing stack including building a full CD/CI pipeline',
      'Achieved "Subject Matter Expert" status on multiple components of the code base',
      'Served as Scrum Leader for daily standup',
    ],
    url: 'https://aws.amazon.com/fsx/',
  },
  {
    id: nanoid(),
    img: 'transeo.png',
    title: 'Transeo - React Developer',
    info: [
      'Led development of 2.0 version of React App',
      'Designed and implemented frontend for new career readiness focused product',
      'Handled migration of existing code to 2.0 version',
    ],
    url: 'https://gotranseo.com/',
  },
  {
    id: nanoid(),
    img: 'fisherman.png',
    title: 'Fisherman - Tech Lead',
    info: [
      'Led a team of 4 interns building an iOS app and a Django backend',
      'Designed overall architecture and database schema',
      'Implented MVVM front end using programmatically generated child components',
      'Developed self-service checkout process on a React app, including Stripe integration',
    ],
    url: 'https://gofisherman.com/',
  },
  {
    id: nanoid(),
    img: 'project.jpg',
    title: 'Independent Development Work',
    info: [
      'Built a React Native app with a Django backend for a non-profit, Enjoy Life Education',
      'Developed a Gatsby/React/Contentful web app to highlight student work in a course I used to TA for',
      'Prototyped a variety of iOS apps experimenting with CoreML, GameKit, ARKit, mostly for academic purposes',
    ],
    url: 'https://github.com/wfarley16',
  },
  {
    id: nanoid(),
    img: 'apple.png',
    title: 'Apple Campus Rep at Boston College',
    info: [
      'Led product demos expanding utilization of iPads in the classroom',
      'Expanded territory to include additional nearby universities',
    ],
    url: 'https://github.com/wfarley16',
  },
  {
    id: nanoid(),
    img: 'project.jpg',
    title: 'AI in the Classroom - Research Lead',
    info: [
      'Built Alexa skills oriented at assisting professors in the classroom',
      'Met with key stakeholders at Boston College to understand customer needs'
    ],
  },
  {
    id: nanoid(),
    img: 'trip.png',
    title: 'Tripadvisor - Software Engineering Intern',
    info: [
      'Developed fully automated IT request portal that is now used globally',
    ],
    url: 'https://www.tripadvisor.com/',
  },
];

// CONTACT DATA
export const contactData = {
  cta: '',
  btn: '',
  linkedin: 'https://www.linkedin.com/in/williamrfarley/',
};

// FOOTER DATA
export const footerData = {
  networks: [
    {
      id: nanoid(),
      name: 'twitter',
      url: '',
    },
    {
      id: nanoid(),
      name: 'codepen',
      url: '',
    },
    {
      id: nanoid(),
      name: 'linkedin',
      url: '',
    },
    {
      id: nanoid(),
      name: 'github',
      url: '',
    },
  ],
};

// Github start/fork buttons
export const githubButtons = {
  isEnabled: false, // set to false to disable the GitHub stars/fork buttons
};
